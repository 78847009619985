<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Empleados"
      subtitle="| Administrador de Empleados"
      class="heading-block"
    >
      <template #extra>
        <div class="mt-5 mt-sm-0 ml-sm-3">
          <b-button
            variant="alt-primary"
            class=""
            href="javascript:void(0)"
            v-click-ripple
            v-b-popover.hover.bottom="'Alta de Empleado'"
            @click="ShowModalEmpleado()"
            style="width: 200px"
          >
            <i class="fa fa-plus-square mr-1"></i> Nuevo Empleado
          </b-button>
          <!-- Small Block Modal -->
          <b-modal
            id="modal-empleados"
            size="lg"
            body-class="p-0"
            hide-footer
            hide-header
          >
            <div
              class="block block-rounded block-themed block-transparent mb-0"
            >
              <div class="block-header bg-primary-dark">
                <h3 class="block-title">
                  <i class="fa fa-user mr-1"></i> Alta de Empleado
                </h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    @click="ShowModalEmpleado()"
                  >
                    
                  </button>
                  <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-empleados')"></i>
                </div>
              </div>
              <div class="block-content font-size-sm">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-row class="ml-4 mt-3">
                    <b-col sm="4">
                      <b-form-group label-for="no_empleado">
                        <template #label>
                          Número empleado<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="no_empleado"
                          name="no_empleado"
                          placeholder="-"
                          size="sm"
                          v-model="form.no_empleado"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="8">
                      <b-form-group label-for="nombre_completo">
                        <template #label>
                          Nombre completo<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="nombre_completo"
                          name="nombre_completo"
                          placeholder="-"
                          size="sm"
                          v-model="form.nombre_completo"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row class="ml-4 mt-3">
                    <b-col sm="6">
                      <b-form-group label-for="salario_diario">
                        <template #label>
                          Salario diario<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="salario_diario"
                          name="salario_diario"
                          placeholder="-"
                          size="sm"
                          :value="form.salario_hora*8"
                          disabled
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group label-for="salario_hora">
                        <template #label>
                          Salario hora<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="salario_hora"
                          name="salario_hora"
                          placeholder="-"
                          size="sm"
                          v-model="form.salario_hora"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row class="ml-4 mt-3">
                    <b-col sm="6">
                      <b-form-group label-for="collaborator_position_id">
                        <template #label>
                          Puesto <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          id="collaborator_position_id"
                          name="collaborator_position_id"
                          placeholder="-"
                          size="sm"
                          type="text"
                          v-model="form.collaborator_position_id"
                        >
                          <option v-for="(data, item) in puestos" :value="data.id" :key="item">
                            {{ data.name }}
                          </option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group label-for="collaborator_area_id">
                        <template #label>
                          Area <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          id="collaborator_area_id"
                          name="collaborator_area_id"
                          placeholder="-"
                          size="sm"
                          type="text"
                          v-model="form.collaborator_area_id"
                        >
                          <option v-for="(data, item) in areas" :value="data.id" :key="item">
                            {{ data.name }}
                          </option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <br />
                </form>
              </div>
              <div
                class="block-content block-content-full text-right border-top"
              >
                <b-button
                  variant="alt-primary"
                  class="mr-1"
                  @click="$bvModal.hide('modal-empleados')"
                  >Cerrar</b-button
                >
                    <template v-if="edit == false">
                      <b-button variant="primary" @click="CreateEmpleado()"
                        >Guardar</b-button
                      >
                    </template>
                    <template v-else> 
                      <b-button variant="danger" @click="UpdateEmpleado()"
                        >Actualizar</b-button
                      >
                    </template>
                   

              </div>
            </div>
          </b-modal>
          <!-- END Small Block Modal -->
        </div>
      </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Busqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <b-overlay :show="showoverlay" rounded="sm">
            <div class="table-responsive">
              <b-table :items="empleados" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
                  
                  <template #cell(actions)="row">
                      <b-button size="sm" @click="EditEmpleado(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-pencil-alt"></i>
                      </b-button>
                      <b-button size="sm" @click="DeleteEmpleado(row.item.id)"  class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-times"></i>
                      </b-button>
                  </template>
              </b-table>
            </div>
        </b-overlay>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import Empleados from "../../../api/admin/empleados/Empleados";
import Area from "../../../api/admin/empleados/Areas";
import Puesto from "../../../api/admin/empleados/Puestos";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      empleados: [],
      puestos:[],
      areas:[],
      filter: null,
      timeout: null,
      edit : false,
      showoverlay:false,
      fields: [
        {key: 'no_empleado',sortable: true, label: '# EMPLEADO'},
        {key: 'nombre_completo',sortable: true, label: 'NOMBRE'},
        {key: 'collaborator_area',formatter: function(value) {
              if(value){
                const jsonStr = JSON.stringify(value)
                const jsonObj = JSON.parse(jsonStr)
                return jsonObj.name;
              }
              return "-";
              //return value.client.nombre_comercial
          },sortable: true, label: 'ÁREA'},
        {key: 'collaborator_position', formatter: function(value) {
              if(value){
                const jsonStr = JSON.stringify(value)
                const jsonObj = JSON.parse(jsonStr)
                return jsonObj.name;
              }
              return "-";
              //return value.client.nombre_comercial
          },sortable: true, label: 'PUESTO'},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      form: {
        id:"",
        no_empleado:"",
        nombre_completo:"",
        salario_diario:"",
        salario_hora:"",
        collaborator_position_id:"",
        collaborator_area_id:""
      },
      errors:[],
    };
  },
  computed: {
    totalRows() {
      return this.empleados.length
    }
  },
  methods: {

    AllAreas() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      Area.GetAllAreas(auth)
        .then((response) => {
          this.areas = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },


    AllPuestos() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      Puesto.GetAllPuestos(auth)
        .then((response) => {
          this.puestos = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },


    AllEmpleados() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      Empleados.GetAllEmpleados(auth)
        .then((response) => {
          this.empleados = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateEmpleado() {
      this.$bvModal.hide("modal-empleados");
      if (this.form.nombre_completo ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          this.form.salario_diario = this.form.salario_hora * 8
          Empleados.PostCreateEmpleado(this.form, auth)
            .then((response) => {
              this.AllEmpleados();
              this.showoverlay = false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = {
                  id:"",
                  no_empleado:"",
                  nombre_completo:"",
                  salario_diario:"",
                  salario_hora:"",
                  collaborator_position_id:"",
                  collaborator_area_id:""          
              };
              
            })
            .catch((error) => {
              const obj = error.response.data.errors;
              Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
              );
              this.AllEmpleados();
            });
      }else{
         this.errors = []
          if (!this.form.nombre_completo) {
              let me=this
              me.errors.push('El nombre es requerido')
          }
          Swal.fire({
            title: "Corrija los siguientes errores",
            icon: "error",
            html: this.errors
                .map(item => `<p> <li>${item}</li></p>`)
                .join('')
          });
          this.AllEmpleados();
      }
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModalEmpleado() {
      this.form = {
        id:"",
        no_empleado:"",
        nombre_completo:"",
        salario_diario:"",
        salario_hora:"",
        collaborator_position_id:"",
        collaborator_area_id:""
      };
      this.$bvModal.show("modal-empleados");
      this.edit = false;
    },

    EditEmpleado(data) {
      this.$bvModal.show("modal-empleados");
      this.edit = true;
      this.form = {
        id: data.id,
        no_empleado: data.no_empleado,
        nombre_completo: data.nombre_completo,
        salario_diario: data.salario_diario,
        salario_hora: data.salario_hora,
        collaborator_position_id: data.collaborator_position_id,
        collaborator_area_id: data.collaborator_area_id
      };
    },

    UpdateEmpleado(){
        this.$bvModal.hide("modal-empleados");
        this.showoverlay = true;
        let auth = JSON.parse(localStorage.autentication);
        Empleados.UpdateEmpleado(this.form, auth)
        .then((response) => {
          this.AllEmpleados();
          this.showoverlay = false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.form = {
            id:"",
            no_empleado:"",
            nombre_completo:"",
            salario_diario:"",
            salario_hora:"",
            collaborator_position_id:"",
            collaborator_area_id:""
          };          
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },

    DeleteEmpleado(id){
        Swal.fire({
            title: "¿Esta seguro de eliminar el registro?",
            text: "¿Eliminar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(resultado => {
            if (resultado.value) {
                 this.showoverlay = true;
                let auth = JSON.parse(localStorage.autentication);
                Empleados.DeleteByEmpleado(id, auth)
                .then((response) => {
                  this.AllEmpleados();
                  this.showoverlay = false;
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });
                  
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Que mal!",
                    text: error.message,
                    icon: "error",
                  });
                });
            } else {
                // Dijeron que no
                console.log("*NO se eliminó en registro*");
            }
        });

        
    },
  },

  mounted() {
    let me = this;
    me.AllEmpleados();
    me.AllAreas();
    me.AllPuestos();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
