import Api from "../../Api";


const END_POINT_ALLPUESTOS = 'user/collaborators/collaborator-positions/all';
const END_POINT_CREATEPUESTO = 'user/collaborators/collaborator-positions/create';
const END_POINT_UPDATEPUESTO = 'user/collaborators/collaborator-positions/update';
const END_POINT_DELETEPUESTO = 'user/collaborators/collaborator-positions/delete';

export default {
    GetAllPuestos(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLPUESTOS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreatePuesto(data, auth) {
        return Api.post(END_POINT_CREATEPUESTO, {
            'name': data.name,
            'tipo_nomina': data.tipo_nomina,
            'salario_diario': data.salario_diario,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdatePuesto(data, auth) {
        return Api.patch(END_POINT_UPDATEPUESTO, {
            'id': data.id,
            'name': data.name,
            'tipo_nomina': data.tipo_nomina,
            'salario_diario': data.salario_diario,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByPuesto(id, auth) {
        return Api.delete(END_POINT_DELETEPUESTO + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}