import Api from "../../Api";


const END_POINT_ALLEMPLEADOS = 'user/collaborators/all';
const END_POINT_CREATEEMPLEADO = 'user/collaborators/create';
const END_POINT_UPDATEEMPLEADO = 'user/collaborators/update';
const END_POINT_DELETEEMPLEADO = 'user/collaborators/delete';

export default {
    GetAllEmpleados(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLEMPLEADOS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateEmpleado(data, auth) {
        return Api.post(END_POINT_CREATEEMPLEADO, data, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateEmpleado(data, auth) {
        return Api.patch(END_POINT_UPDATEEMPLEADO, data, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByEmpleado(id, auth) {
        return Api.delete(END_POINT_DELETEEMPLEADO + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}