import Api from "../../Api";


const END_POINT_ALLAREA = 'user/collaborators/collaborator-areas/all';
const END_POINT_CREATEAREA = 'user/collaborators/collaborator-areas/create';
const END_POINT_UPDATEAREA = 'user/collaborators/collaborator-areas/update';
const END_POINT_DELETEAREA = 'user/collaborators/collaborator-areas/delete';

export default {
    GetAllAreas(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLAREA, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateArea(data, auth) {
        return Api.post(END_POINT_CREATEAREA, {
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateArea(data, auth) {
        return Api.patch(END_POINT_UPDATEAREA, {
            'id': data.id,
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByArea(id, auth) {
        return Api.delete(END_POINT_DELETEAREA + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}